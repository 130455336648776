import SignUpForm from './components/SignUpForm';
import Confirm from './components/Confirm';
import Location from './components/LocationHeader';
import Calendar from './components/Calendar';
import {Spinner,Modal} from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
import "./App.css"
import './bootstrap-iso.css';



function App() {

const[centers, setCenters] = useState([]);
const [accountId, setAccountId] = useState('');
const [room, setRoom] = useState('');
const [consult, setConsult] = useState('');
const[formValidated, setFormValidated] = useState(false);
const[loading, setLoading] = useState(false);
const[showConfirm, setShowConfirm] = useState(false);
const[showCalender, setShowCalendar] = useState(false);
const[selectedCenter, setSelectedCenter] = useState('');
const[timezone, setTimezone] = useState('America/Phoenix');
const[selectedDate, setSelectedDate] = useState('');
const[multipleCenters, setmultipleCenters] = useState(false);

useEffect(()=>{
})


  return (
   <div className="bootstrap-iso App">
   <div className="row">
   <div className="col-md-12">
   { loading? <Modal size='lg' centered show={loading}>        <Modal.Title id="contained-modal-title-vcenter">
          Loading
        </Modal.Title><Modal.Body>      <Spinner animation="grow" variant="primary" />
      <Spinner animation="grow" variant="secondary" />
      <Spinner animation="grow" variant="success" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="warning" />
      <Spinner animation="grow" variant="info" />
      <Spinner animation="grow" variant="light" />
      <Spinner animation="grow" variant="dark" /></Modal.Body></Modal>:null}
   {showConfirm ? <Confirm date={selectedDate}></Confirm>
        : null }
   {!formValidated ? <SignUpForm setAccountId = {setAccountId} setShowCalendar = {setShowCalendar} setFormValidated = {setFormValidated} setLoading = {setLoading}></SignUpForm> : null}
</div>
   <div className="col-md-12">
   {showCalender? <Calendar
          selectedCenter = {selectedCenter}
          accountId = {accountId}
          timezone= {timezone}
          room = {room}
          type = {consult}
          setSelectedDate = {setSelectedDate}
          setShowCalendar = {setShowCalendar}
          setShowConfirm = {setShowConfirm}
        />: null }
</div>
</div>
</div>
  );  
}

export default App;