import LocationItem from './LocationItem';
function Locations(props) {
    return (  <div className="bootstrap-iso">
      <h5 className='trubluetext'>Where do you want to meet us?</h5>

      {props.centers.map((center) => ( 
      <LocationItem name= {center.Name}
                    key={center.Id}
                    centerId = {center.Id}
                    phone={center.Main_Phone__c}
                    street={center.Street__c}
                    city={center.City__c}
                    state={center.State__c}
                    zip={center.Postal_Code__c}
                    timezone={center.Time_zone__c}
                    setTimezone={props.setTimezone}
                    setSelectedCenter={props.setSelectedCenter}
                    setFormValidated={props.setFormValidated}
                    setRoom={props.setRoom}
                    setConsult = {props.setConsult}
                    setShowCalendar={props.setShowCalendar}>
                   
                    </LocationItem>
    ))}
    </div>); 
  }
  export default Locations;