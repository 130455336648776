import React, { useState } from 'react';
import { Form, Button,Col,Row,FloatingLabel} from 'react-bootstrap';


function SignUpForm(props)
{
  const [validated, setValidated] = useState(false);
  
  async function handleSubmit (event) {
    const form = event.currentTarget;
    var data = {};
    const formData = new FormData(event.currentTarget);
    event.preventDefault();
    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
      
    }
    else
    {
      setValidated(true);
      props.setLoading(true);
      let response = await fetch('https://salesforceservices.azurewebsites.net/api/createpatient?code=RVT_srVOwHUWJScEhAzyEoiumgqYYLyFr13_JC5m5HGIAzFuIaafdQ==&company=creativedynamicmanagement', {method: "POST",
                 body: JSON.stringify(data)}).then((result)=>{
                  return result.json();
                 })
                 console.log(response);
                 console.log(props);
                 if(response)
                 {
                   console.log(response);
                   props.setAccountId(response[0].Id);
                   props.setFormValidated(true);
                   props.setShowCalendar(true);
                   props.setLoading(false);
                 }
    }
    console.log(data);

  };



  return (
    <div>
    <h5 className='trubluetext'>Let's Get Started ...</h5>
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
      <Form.Group as={Col} md="5" controlId="validationCustom01">
      <FloatingLabel
        controlId="floatingInput"
        label="First name"
        className="mb-3"
      >
        <Form.Control
          required
          type="text"
          placeholder="First name"
          name="firstName"
        />
        </FloatingLabel>
        <Form.Control.Feedback id="validationCustom01" type="invalid">Please provide your First Name.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="5" controlId="validationCustom02">
      <FloatingLabel
        controlId="floatingInput"
        label="Last name"
        className="mb-3"
      >
        <Form.Control
          required
          type="text"
          name="lastName"
          placeholder="name"
        />
        </FloatingLabel>
        <Form.Control.Feedback type="invalid">Please provide your Last Name.</Form.Control.Feedback>
      </Form.Group>
      </Row>
      <Row className="mb-3">
      <Form.Group className="mb-3" as={Col} md="5" controlId="formBasicEmail">
      <FloatingLabel
        controlId="floatingInput"
        label="Email address"
        className="mb-3"
      >
        <Form.Control 
          required 
          type="email" 
          placeholder="example@truesmile.com" 
          name="personEmail"/>
        </FloatingLabel>
        <Form.Text className="text-muted">
          We'll never share your email but we may email about information relating to your appointment.
        </Form.Text>
        <Form.Control.Feedback type="invalid">Please provide your Email address.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="4" controlId="validationCustom03" pattern="[0-9]{11,14}">
      <FloatingLabel
        controlId="floatingInput"
        label="Phone"
        className="mb-3"
      >
        <Form.Control
          required
          name="personMobilePhone"
          placeholder="555-555-5555"
          type="tel"
        />
        </FloatingLabel>
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      </Form.Group>  
      </Row>
      <Row className="mb-3">
      <Form.Group as={Col} md="5" controlId="validationCustom01">
      <FloatingLabel
        controlId="floatingInput"
        label="Home City"
        className="mb-3"
      >
        <Form.Control
          required
          type="text"
          placeholder="Home Address City"
          name="PersonMailingCity"
 
        />
        </FloatingLabel>
        <Form.Control.Feedback id="validationCustom01" type="invalid">Please provide your home address city.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="5" controlId="validationCustom02">
      <FloatingLabel
        controlId="floatingInput"
        label="Home Address State"
        className="mb-3"
      >
        <Form.Select  size="sm" name="PersonMailingState" >
        <option></option>  
        <option>Alabama</option>
        <option>Alaska</option>
<option>Arizona</option>
<option>Arkansas</option>
<option>California</option>
<option>Colorado</option>
<option>Connecticut</option>
<option>Delaware</option>
<option>Florida</option>
<option>Georgia</option>
<option>Hawaii</option>
<option>Idaho</option>
<option>Illinois</option>
<option>Indiana</option>
<option>Iowa</option>
<option>Kansas</option>
<option>Kentucky</option>
<option>Louisiana</option>
<option>Maine</option>
<option>Maryland</option>
<option>Massachusetts</option>
<option>Michigan</option>
<option>Minnesota</option>
<option>Mississippi</option>
<option>Missouri</option>
<option>Montana</option>
<option>Nebraska</option>
<option>Nevada</option>
<option>New Hampshire</option>
<option>New Jersey</option>
<option>New Mexico</option>
<option>New York</option>
<option>North Carolina</option>
<option>North Dakota</option>
<option>Ohio</option>
<option>Oklahoma</option>
<option>Oregon</option>
<option>Pennsylvania</option>
<option>Rhode Island</option>
<option>South Carolina</option>
<option>South Dakota</option>
<option>Tennessee</option>
<option>Texas</option>
<option>Utah</option>
<option>Vermont</option>
<option>Virginia</option>
<option>Washington</option>
<option>West Virginia</option>
<option>Wisconsin</option>
<option>Wyoming</option>
      </Form.Select>
        </FloatingLabel>
      </Form.Group>
      </Row>

      <Row className="mb-6">
      <Form.Group as={Col} md="12" controlId="validationCustom03">
        <Form.Label>Any notes you want to share with the team?</Form.Label>
        <Form.Control
          as="textarea"
          name="Description"
          rows={4}
        />
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      </Form.Group> 
      </Row>
      <br/>
      <Button type="submit" className="btn trugreen" >SUBMIT FORM</Button>
    </Form>
    </div>
  )
}

export default SignUpForm;