function Confirm(props) {


  
    return (
      <div>
        <h4 className='trubluetext'>Thanks! We'll be giving you a call.</h4>
        <div> 
          <h6 className='text-muted'>Your appointment is scheduled for:</h6> 
          <h5><b>{props.date}</b></h5>
         
</div>
      </div>); 
  }
  export default Confirm;