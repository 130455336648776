import {Card} from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import moment from 'moment-timezone';



function Calendar(props) {

    function eventMount(args)
{
  args.event.setProp('title','<b>Available</b>');
  args.el.querySelector('.fc-list-event-title').innerHTML = '<button type="button" class="trublue btn btn-primary btn-sm">Schedule</button>';
}
async function eventClick(args)
{
 props.setSelectedDate(moment.tz(args.event.start, props.timezone).format('dddd  MMMM DD, YYYY') + ' at ' + moment.tz(args.event.start,props.timezone).format('hh:mm A'));
 console.log(args.event.start);
 console.log(args.event.id);
 console.log('Account id is:' + props.accountId);

 const api_call = await fetch(`https://salesforceservices.azurewebsites.net/api/scheduleappointment?code=VLtqMrE-xhaC1z_An-lzAo2o5nlI8Wy_4myfGb7nYf1XAzFu7vt-WA==&company=creativedynamicmanagement&e=${args.event.id}&a=${props.accountId}`);
 console.log(api_call);
 props.setShowCalendar(false);
 props.setShowConfirm(true);
}

    return (<Card className='bootstrap-iso'>
        <Card.Body>
          <h5 className="trubluetext">Schedule a time for your Consultation (Times are in Mountain Standard Time)</h5>
          <FullCalendar
          plugins={[ listPlugin,momentTimezonePlugin ]}
          initialView="listWeek"
          navLinks = {false}        
          events = {'https://salesforceservices.azurewebsites.net/api/getavailableevents?code=eWwDcmjZHKIKTipaiIpoRT75fcgag-DyrNISvDHzMYh2AzFuuQoqAA==&company=creativedynamicmanagement&centerId=a015f00000QwsER&room='}
          timeZone= {props.timezone}
          height= 'auto'
          titleFormat = {{month: 'long', day: 'numeric' }}
          eventDidMount = {eventMount}
          eventClick = {eventClick}
        />
        </Card.Body>
      </Card>); 
  }
  export default Calendar;